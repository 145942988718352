import React from "react";
import { Box, Button, Heading, Text, VStack, HStack, Divider, UnorderedList, ListItem, Center, Flex } from "@chakra-ui/react";
import { PRIMARY_COLOR, PRIMARY_FONT, SECONDARY_BG_SHADE_COLOR } from "../constant";

const PricingCard = (props) => {
    const { 
        title, 
        price, 
        features, 
        buttonText, 
        bigComment, 
        smallComment,
        isFromHome,
        pricingDetails,
        setIsContactOpen
    } = props;


    const openContactModal = () => {
        isFromHome.current = true;
        pricingDetails.current = title + " - " + bigComment;
        setIsContactOpen(true);
    }
  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={6}
      textAlign="center"
      transition="transform 0.3s, box-shadow 0.3s"
      _hover={{ transform: "translateY(-10px)", boxShadow: "xl", cursor:"pointer", border:"1px", borderColor:PRIMARY_COLOR}}
      boxShadow="md"
      minHeight="780px"
      minWidth={{base:"95%", sm:"95%",md:"400px",lg:"400px", xl:"400px"}}
      maxWidth={{base:"95%", sm:"95%",md:"400px",lg:"400px", xl:"400px"}}
      fontFamily={PRIMARY_FONT}
      mx={{base:"10px", sm:"20px",md:"10px",lg:"0px", xl:"0px"}}>
      <Center h="50px">
        <Heading size="md" mb={4} fontFamily={PRIMARY_FONT} textColor={PRIMARY_COLOR}>
          {title}
        </Heading>
      </Center>
      <Divider my={4} />
      <Center>
      <HStack>
        <Text fontSize="md" fontWeight="bold" textColor={PRIMARY_COLOR}>from </Text>
        <Text fontSize="2xl" fontWeight="bold" textColor={PRIMARY_COLOR} my="30px">
          {price}
        </Text>
      </HStack>
      </Center>
      <VStack h="150px" alignItems="left">
        <Text textColor={PRIMARY_COLOR} textAlign="justify" fontSize="md">{bigComment}</Text>
        <Text textColor={PRIMARY_COLOR} textAlign="justify" fontSize="sm" my="20px">{smallComment}</Text>
      </VStack>
      <VStack spacing={3} mb={6} alignItems="left" h="570px">
        <Center><Heading size="sm" fontFamily={PRIMARY_FONT} textColor={PRIMARY_COLOR} mt="20px">{"Details"}</Heading></Center>
        {features.map((feature, index) => (
          <UnorderedList key={index}>
            <ListItem color={PRIMARY_COLOR}><Text textColor={PRIMARY_COLOR} textAlign="justify" fontSize="sm">{feature}</Text></ListItem>
          </UnorderedList>
        ))}
      </VStack>
      <Button size="lg" bgColor={PRIMARY_COLOR} mb="20px" color="white" onClick={() => {openContactModal()}}>
        {buttonText}
      </Button>
    </Box>
  );
};

const PricingSection = (props) => {
    const {
        isFromHome,
        pricingDetails,
        pricingPlans,
        setIsContactOpen
    } = props;

  return (
    <HStack spacing={8} justify="center" wrap="wrap">
      {pricingPlans.map((plan, index) => (
        <PricingCard
          key={index}
          title={plan.title}
          price={plan.price}
          features={plan.features}
          buttonText={plan.buttonText}
          bigComment={plan.bigComment}
          smallComment={plan.smallComment}
          isFromHome={isFromHome}
          pricingDetails={pricingDetails}
          setIsContactOpen={setIsContactOpen}
        />
      ))}
    </HStack>
  );
};

export default PricingSection;
