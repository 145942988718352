import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
  Flex,
} from '@chakra-ui/react';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';

const FAQ = () => {
  const faqData = [
    {
      question: "What is Sequence?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    },
    {
      question: "Why do you choose us ?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    },
    {
      question: "What does you require to use our platform ?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    },
  ];

  return (
    <Flex w="100%" justify="center">
        <Accordion allowMultiple  w={{ base: "100%", sm:"100%", md: "60%", lg: "60%", xl: "60%" }} mt="20px">
        {faqData.map((item, index) => (
            <AccordionItem key={index} border="none" w="100%" mt="20px" borderRadius="md">
            {({isExpanded}) => (
                <>
                    <h2>
                        <AccordionButton _expanded={{ bg: '#7201a9', color: '#ffffff' }} py={4} h="100px">
                        { isExpanded?(<MinusIcon fontSize='12px' mr="50px"/>) :(<AddIcon  mr="50px" fontSize='12px' />)}
                        <Box flex="1" textAlign="left" fontWeight="bold" fontSize="25px" fontFamily="Moderustic">
                            {item.question}
                        </Box>
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} border="1px" borderBottomLeftRadius="md" borderBottomRightRadius="md">
                        <Text fontFamily="Moderustic">{item.answer}</Text>
                    </AccordionPanel>
                </>
            )}
        </AccordionItem>
        ))}
        </Accordion>
    </Flex>
  );
};

export default FAQ;

