import xpressFlowers from "./assets/clients/xpressFlowers.png";
import kingstonClinic from "./assets/clients/kingstonClinic.png";
import qci from "./assets/clients/qci.png";
import tanglineDental from "./assets/clients/tanglineDental.png";
import smileFocus from "./assets/clients/smileFocus.png";
import tenderObjects from "./assets/clients/tenderObjects.png";

import acra from "./assets/ecosystem/acra.png";
import cloudKinetics from "./assets/ecosystem/cloudKinetics.png";
import dbsBank from "./assets/ecosystem/dbsBank.png";
import empHero from "./assets/ecosystem/empHero.png";
import isca from "./assets/ecosystem/isca.png";
import lawSocietySingapore from "./assets/ecosystem/lawSocietySingapore.png";
import manulifeFinancial from "./assets/ecosystem/manulifeFinancial.png";
import ocbc from "./assets/ecosystem/ocbc.png";
import sgDigital from "./assets/ecosystem/sgDigital.png";
import xero from "./assets/ecosystem/xero.png";

export const isLocalhost = window.location.hostname === 'localhost';
export const SEND_EMAIL_URL = isLocalhost ? "http://localhost:3001/api/v1/send-email": "https://api.sequence.sg/api/v1/send-email";
export const PRIMARY_COLOR = "#7201a9";
export const SECONDARY_BG_SHADE_COLOR = "#f8eafe";
export const THIRD_BG_SHADE_COLOR = "#f2dafe";
export const BG_SHADE_COLOR = "#7e7ab7";
export const WHATSAPP_GREEN_COLOR = "#25d366";
export const OSOME_BEIGE_COLOR = "#f3ede6";
export const YELLOW_BG_COLOR = "#ffe2a3";

export const SOLAR_BANNER_L = "https://cdn.sequence.sg/SOLAR_SYSTEM_LG.jpg";
export const SOLAR_BANNER_BA = "https://cdn.sequence.sg/SOLARSYSTEM_BASE2.jpg";

export const CS_ROBO_PNG = "https://cdn.sequence.sg/CorporateSecretarialPNG.png";
export const AA_ROBO_PNG = "https://cdn.sequence.sg/AccountingandAuditPNG.png";
export const HR_ROBO_PNG = "https://cdn.sequence.sg/HRManagementPNG.png";
export const BS_ROBO_PNG = "https://cdn.sequence.sg/BusinessConsultancyPNG.png";

export const DPP_PDF = "https://cdn.sequence.sg/sequence_dpp.pdf";
export const TOS_PDF = "https://cdn.sequence.sg/sequence_tos.pdf";



export const PRIMARY_FONT = "Moderustic";

export const CS_DETAILS = [
    "Every business entity in Singapore must comply with periodic filing requirements with government agencies such as the Accounting and Corporate Regulatory Authority (ACRA), the Inland Revenue Authority of Singapore (IRAS), the Ministry of Manpower (MOM). ",
    "There are also mandatory filing requirements for some business entity transactions - for example in a company (which is governed by the Companies Act 1967), the appointment of certain officers or a special resolution must be lodged with ACRA within a specified period of time.", 
    "There are other rules and regulations governing each type of business entity, such as Limited Liability Partnerships (LLP), Variable Capital Companies (VCC) etc. ",
    "The table below sets out a non-exhaustive list of our corporate secretarial services and the prices thereof. Do reach out to us at info@sequence.sg for more information on the prices or other services that you may need."
];

export const AA_DETAILS = [
    "Every business has to keep a good sense of its numbers. As you would already be busy working on your core business activities, it can be overwhelming or just not worth your attention to manage all the accounting transactions and activities that need to occur daily.",
    "These include keeping the source documents and information, updating it into a real-time view of the financials and keeping it ready for mandatory annual reporting to the government, keeping track of the payables and receivables, making adjustments to the entries in the books etc. ",
    "The table below sets out a non-exhaustive list of our accounting services and the prices thereof. Do reach out to us at info@sequence.sg for more information on the prices or other services that you may need."
];

export const SERVICE_INDEXES = {
    "Corporate Secretarial":0,
    "Accounting & Audit":1,
    "HR Management":2,
    "Business Consultancy":3,
    "Guides & FAQs":4,
    "Contact Us":5,
}

export const SERVICES_DETAILS = [
    {
        title:"Corporate Secretarial",
        image:CS_ROBO_PNG,
        pricingPath:"/csPricing",
        details:[
            "Every business entity in Singapore must comply with periodic filing requirements with government agencies such as the Accounting and Corporate Regulatory Authority (ACRA), the Inland Revenue Authority of Singapore (IRAS), the Ministry of Manpower (MOM). ",
            "There are also mandatory filing requirements for some business entity transactions - for example in a company (which is governed by the Companies Act 1967), the appointment of certain officers or a special resolution must be lodged with ACRA within a specified period of time.", 
            "There are other rules and regulations governing each type of business entity, such as Limited Liability Partnerships (LLP), Variable Capital Companies (VCC) etc. ",
            "As your company Secretary, our team of ex-lawyers in Sequence can help you manage the mandatory periodic filing requirements, maintain your statutory records, and advise on and complete any transaction that you may be intending to do, for any kind of business entity."
        ],
        primaryDetail:"As your company Secretary, our team of ex-lawyers in Sequence can help you manage the mandatory periodic filing requirements, maintain your statutory records, and advise on and complete any transaction that you may be intending to do, for any kind of business entity.",
        isNoPricing:false
    },
    {
        title:"Accounting & Auditing",
        image:AA_ROBO_PNG,
        pricingPath:"/aaPricing",
        details:[
            "Every business has to keep a good sense of its numbers. As you would already be busy working on your core business activities, it can be overwhelming or just not worth your attention to manage all the accounting transactions and activities that need to occur daily.",
            "These include keeping the source documents and information, updating it into a real-time view of the financials and keeping it ready for mandatory annual reporting to the government, keeping track of the payables and receivables, making adjustments to the entries in the books etc.",
            "Our team of chartered accountants in Sequence is well-versed in the Singapore Financial Reporting Standards and other accounting standards.",
            "All you will have to do is to pass over the accounting documents to us as they come to you, and the rest will be taken care of by us."
        ],
        primaryDetail:"Our team of chartered accountants in Sequence is well-versed in the Singapore Financial Reporting Standards and other accounting standards. All you will have to do is to pass over the accounting documents to us as they come to you, and the rest will be taken care of by us.",
        isNoPricing:false
    },
    {
        title:"HR Management",
        image:HR_ROBO_PNG,
        pricingPath:"/hrPricing",
        details:[
            "Every team member needs your attention to administer the basics - payroll, leaves, benefits, claims, work passes, taxes, provident or social security funds.",
            "We can save you dozens of hours each month with our HR-related services.",
            "We can onboard and administer HR software on your behalf - so that you can take advantage of the benefits without having to worry about the hassles of using a new software for you and your team.",
            "We also have a selected panel of recruitment agencies in Singapore and regionally, and can help you source for the talent you need to build your team.", 
            "Do reach out to us at info@sequence.sg for more information on our HR-related services and the prices thereof.",
        ],
        primaryDetail:"Every team member needs your attention to administer the basics - payroll, leaves, benefits, claims, work passes, taxes, provident or social security funds. We can manage these on your behalf.",
        isNoPricing:true
    },
    {
        title:"Business Consultancy",
        image:BS_ROBO_PNG,
        pricingPath:"/bscPricing",
        details:[
            "The businesses today may often require cross-functional experience for resolution or to reach a breakthrough.",
            "We understand that you may do it better by yourself but you may not have the bandwidth to work on all thel issues or problems in your business. ",
            "With our team of ex-lawyers and chartered accountants, we can help you achieve your goals.",
            "We have 2 main categories of business consultancy needs that we serve:",
            "1. Business problems or business scenarios to optimise",
            "2. Sale & Purchase (S&P) or Mergers & Acquisitions (M&A) - which includes activities such as valuation, due diligence and/or assistance on documentation via our partner law firms.",
            "Do reach out to us at info@sequence.sg with your problem statements or your S&P / M&A requirements, and we will take care of it together."
        ],
        primaryDetail:"The businesses today may often require cross-functional experience for resolution or to reach a breakthrough. Our team can assist in your business problems or business scenarios to optimise; and transactions relating to Sale & Purchase (S&P) or Mergers & Acquisitions (M&A).",
        isNoPricing:true
    }
]; 

export const COMPANY_LOGOS = [
    xpressFlowers,
    kingstonClinic,
    qci,
    tanglineDental,
    smileFocus,
    tenderObjects
];

export const ECO_SYSTEM_LOGOS = [
    acra,
    dbsBank,
    empHero,
    isca,
    manulifeFinancial,
    ocbc,
    sgDigital,
    xero
];


export const corporatePricingPlans = [
    {
      title: "Incorporation Package",
      price: "S$ 565",
      bigComment: "For establishing a local company",
      smallComment: "Inclusive of S$ 315 government fees; exclusive of disbursement of S$ 60 government annual filing fee",
      features: [
        "Named Company Secretary to fulfil statutory requirements in Singapore for a local company",
        "Maintenance of all statutory registers and mandatory compliance records", 
        "Holding of Annual General Meeting, and the preparation and filing of all required documents for the AGM (including the Minutes of Meeting) and lodgement of the Annual Return",
        "Facilitation of Bank Account opening"
    ],
      buttonText: "Get Started",
    },
    {
      title: "Foreigner Incorporation Package",
      price: "S$ 2115",
      bigComment: "For establishing a local company - but for foreigners",
      smallComment: "Inclusive of S$ 315 government fees; exclusive of disbursement of S$ 60 government annual filing fee",
      features: [
        "Inclusive of S$ 315 government fees; exclusive of disbursement of S$ 60 government annual filing fee",
        "Provision of named Nominee Director to fulfil statutory requirements in Singapore for a local company",
        "Named Company Secretary to fulfil statutory requirements in Singapore for a local company", 
        "Maintenance of all statutory registers and mandatory compliance records",
        "Holding of Annual General Meeting, and the preparation and filing of all required documents for the AGM (including the Minutes of Meeting) and lodgement of the Annual Return",
        "Facilitation of Bank Account opening",
        "Provision of Registered Office Address at a Central  Business District address (with digital mail scanning services)"
    ],
      buttonText: "Get Started",
    },
    {
      title: "Company Secretary Package",
      price: "S$ 300",
      bigComment: "Keeping you compliant with mandatory annual requirements",
      smallComment: "Exclusive of disbursement of S$ 60 government annual filing fee",
      features: [
        "Named Company Secretary to fulfil statutory requirements in Singapore for a local company", 
        "Maintenance of all statutory registers and mandatory compliance records", 
        "Holding of Annual General Meeting, and the preparation and filing of all required documents for the AGM (including the Minutes of Meeting) and lodgement of the Annual Return"
    ],
      buttonText: "Get Started",
    },
    {
      title: "Provision of Nominee Director",
      price: "S$ 1400",
      bigComment: "Fulfilling the statutory requirement of having a ordinarily locally resident director in Singapore",
      smallComment: "For establishing a local company",
      features: [
        "Provision of named Nominee Director to fulfil statutory requirements in Singapore for a local company", 
        "Bank Account opening, free e-signing"
    ],
      buttonText: "Get Started",
    },
  ];

export const accountingPricingPlans = [
{
    title: "Comprehensive Accounting Services with Tax Compliance",
    price: "S$ 2200",
    bigComment: "Including tax compliance",
    smallComment: "We will handle your Finanical Statments and Reports, ensuring your business is in compliance with Singapore tax and accounting regulations.",
    features: [
    "Updated Monthly Unaudited Financial Statements and Unaudited Annual Financial Statements (as per FYE)",
    "Setting Up Accounting System (exclusive of fees for the Accounting Software such as Xero)", 
    "Corporate Income Tax: Computation and Filing with IRAS for Form C, Form C-S, or Form C-S (Lite)",
    "Bookkeeping Services(up to 150 transactions / year, and additional transactions at $2)",
    "Estimated Chargeable Income (ECI) filing",
    "GST Filing(exclusive of fees for GST Registration)"
],
    buttonText: "Get Started",
},
{
    title: "Comprehensive Accounting Services",
    price: "S$ 1200",
    bigComment: "Excluding tax compliance",
    smallComment: "We will prepare Finanical Statments and Reports in compliance with Singapore Accounting Standards",
    features: [
    "Updated Monthly Unaudited Financial Statements and Unaudited Annual Financial Statements (as per FYE)",
    "Setting Up Accounting System (exclusive of fees for the Accounting Software such as Xero)", 
    "Bookkeeping Services(up to 150 transactions / year, and additional transactions at $2)"
],
    buttonText: "Get Started",
},
];

export const addOnList = [
    {
        column1:"XBRL Filing for Financial Statements",
        column2:"$ 350",
        column3:"Per Transaction",
    },
    {
        column1:"Provision of Registered Office Address (without Mail Scanning Services)",
        column2:"$ 240",
        column3:"Per Year",
    },
    {
        column1:"Mail Scanning Services for Registered Office Address",
        column2:"$ 240",
        column3:"Per Year",
    },
    {
        column1:"GST Registration with IRAS",
        column2:"S$ 360",
        column3:"Per Transaction",
    },
    {
        column1:"Quarterly GST Filing with IRAS",
        column2:"S$ 250",
        column3:"Per Quarter",
    },
    {
        column1:"Corporate Income Tax: Computation and Filing with IRAS for Estimated Chargeable Income (ECI)",
        column2:"S$ 400",
        column3:"Per Transaction",
    },
    {
        column1:"Corporate Income Tax: Computation and Filing with IRAS for Form C, Form C-S, or Form C-S (Lite)",
        column2:"S$ 600",
        column3:"Per Transaction",
    },
    {
        column1:"Bank Account Opening",
        column2:"S$ 720",
        column3:"Per Transaction",
    }
];