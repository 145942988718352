import React from "react";
import { Box, Table, Thead, Tbody, Tr, Th, Td, Text, Card, CardHeader, CardBody } from "@chakra-ui/react";
import { PRIMARY_COLOR, SECONDARY_BG_SHADE_COLOR } from "../constant";

const AddOnTable = (props) => {
    const { title, items } = props;
    return (
        <Card boxShadow="md" borderRadius="md" p={4} m="30px" transition="transform 0.3s, box-shadow 0.3s"
        _hover={{ transform: "translateY(-10px)", boxShadow: "xl", cursor:"pointer", border:"1px", borderColor:PRIMARY_COLOR}}
        >
        <CardHeader>
            <Text fontSize="xl" fontWeight="bold" color={PRIMARY_COLOR}>
            {title}
            </Text>
        </CardHeader>
        <CardBody>
            <Box overflowX="auto">
            <Table variant="striped">
                <Tbody>
                {items.map((item, index) => (
                    <Tr key={index}  bg={SECONDARY_BG_SHADE_COLOR}>
                    <Td>{item.column1}</Td>
                    <Td>{item.column2}</Td>
                    <Td>{item.column3}</Td>
                    </Tr>
                ))}
                </Tbody>
            </Table>
            </Box>
        </CardBody>
        </Card>
    );
};

export default AddOnTable;

const data = [
  { column1: "Item 1", column2: "Value 1", column3: "Description 1" },
  { column1: "Item 2", column2: "Value 2", column3: "Description 2" },
  { column1: "Item 3", column2: "Value 3", column3: "Description 3" },
];
