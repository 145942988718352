import React, { useState } from 'react';
import { HStack, Box, IconButton, Text } from '@chakra-ui/react';
import { FaWallet , FaBell, FaUserTie, FaQuestion  } from 'react-icons/fa';
import { FaPeopleGroup,} from "react-icons/fa6";
import { PRIMARY_COLOR, SERVICE_INDEXES } from '../constant';

function MenuBar(props) {
  const {
    setMenuItems,
    handleScrollToItem
  } = props;

  

  return (
      <HStack as="nav" spacing={4} display={{ base: 'none', md:'none', lg:'flex'}} mx="auto">
        <MenuItem icon={FaUserTie} label="Corporate Secretarial" setMenuItems={setMenuItems} handleScrollToItem={handleScrollToItem}/>
        <MenuItem icon={FaWallet } label="Accounting & Audit" setMenuItems={setMenuItems}  handleScrollToItem={handleScrollToItem}/>
        <MenuItem icon={FaPeopleGroup} label="HR Management" setMenuItems={setMenuItems}  handleScrollToItem={handleScrollToItem}/>
        <MenuItem icon={FaBell} label="Business Consultancy" setMenuItems={setMenuItems}  handleScrollToItem={handleScrollToItem}/>
        <MenuItem icon={FaQuestion} label="Guides & FAQs" setMenuItems={setMenuItems}  handleScrollToItem={handleScrollToItem}/>
      </HStack>
  );
}

function MenuItem({ icon, label, setMenuItems, handleScrollToItem }) {
  const [ iconButtonColor, setIconButtonColor] = useState(PRIMARY_COLOR);
  return (
    <Box onClick={()=>{handleScrollToItem(SERVICE_INDEXES[label])}} textAlign="center" p="5px" _hover={{ bg: PRIMARY_COLOR, cursor:"pointer"}} border= "1px" borderColor="transparent" borderRadius="5px" mr="20px" ml="20px" 
          onMouseEnter={() => {setMenuItems({show:true,name:label}); setIconButtonColor("#ffffff")}}
          onMouseLeave={() => {setMenuItems({show:false,name:""}); ; setIconButtonColor(PRIMARY_COLOR)}}>
      <IconButton
        aria-label={label}
        icon={React.createElement(icon, { boxSize: 40 })}
        isRound
        variant="ghost"
        color={iconButtonColor}
        _hover={{ bg: 'transparent'}}
      />
      <Text mt="-5px"fontSize="sm" color={iconButtonColor} fontFamily="Moderustic" >
        {label}
      </Text>
    </Box>
  );
}

export default MenuBar;
