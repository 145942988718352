import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Input,
  FormControl,
  FormLabel,
  Select,
  Textarea,
  Checkbox,
  Button,
  HStack,
  useToast,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { PRIMARY_COLOR, PRIMARY_FONT, SECONDARY_BG_SHADE_COLOR } from "../constant";
import { EMPTY } from "../LandingPage/Strings/en_strings";
import { countryPhoneCodes } from "../countryCodes";
import { sendEmail } from "../services/serviceManager";
import { isValidEmail } from "../LandingPage/Utilities/utilities";
import { FlagOption } from "./ContactUs";
import { CheckIcon } from "@chakra-ui/icons";

const HomeContactUs = () => {
    const toast = useToast();
    const [ name, setName ] = useState(EMPTY);
    const [ email, setEmail ] = useState(EMPTY);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ checkMailingList, setCheckMailingList ] = useState(false);
    const [ countryCode, setCountryCode ] = useState("+65");
    const [ queryType, setQueryType ] = useState("General Inquiry");
    const [ description, setDescription ] = useState(EMPTY);
    const [ phoneNumber, setPhoneNumber ] = useState(EMPTY);
    const [ isCountryCodeValid, setIsCountryCodeValid ] = useState(true);

    useEffect(() => {
    setIsCountryCodeValid(true);
    },[countryCode]);

    useEffect(() => {
    const lowerCaseEmail = email?.toLowerCase();
    if(email && email !== lowerCaseEmail){
        setEmail(lowerCaseEmail);
    }
    },[email]);

    const sendDescriptionMail = () => {
        const countryCodeItem = countryPhoneCodes.find(element => element.phoneCode === countryCode);
        const isCountryCodeCorrect = countryCodeItem?true:false;
        const isPhoneEmpty = phoneNumber === EMPTY;

        if(!isPhoneEmpty && !isCountryCodeCorrect){
        setIsCountryCodeValid(false);
        }else{
            const query = `${queryType} : ${description}. add to mailing list ? ${checkMailingList? "Yes":"No"}`;
            const details = {
                query: query,
                email:email??"",
                name:name??"",
                number: countryCodeItem?.phoneCode + " - " + phoneNumber
        }

        sendEmail(onSuccess, onFailure, details);
        setIsLoading(true);
        }
    } 

    const resetValues = () => {
        setIsLoading(false);
        setEmail(EMPTY);
        setName(EMPTY);
        setPhoneNumber(EMPTY);
        setCountryCode("+65");
        setDescription(EMPTY);
    }

    const onSuccess = (response) => {
        resetValues();
        toast({
            title: 'Success',
            description: "Query submitted!",
            status: 'success',
            duration: 5000,
            isClosable: true,
        });
    }

    const onFailure = (response) => {
        setIsLoading(false);
        toast({
            title: 'Failed',
            description: "Server error, please wait for sometime. and send your request again",
            status: 'error',
            duration: 5000,
            isClosable: true,
        });
    }

    return (
        <Box p={8} 
            w="1280px" 
            mx="auto" 
            fontFamily={PRIMARY_FONT} 
            bg="white"
            my="30px">
        <Heading textAlign="center" mb={4} color={PRIMARY_COLOR}>
            How can we help you?
        </Heading>
        <Text textAlign="center" fontSize="md" mb={8} color={PRIMARY_COLOR}>
        Leave a message to us and our team of experts will get back to you within a day
        </Text>

        <Flex gap={6}>
            <Box flex="1">
                <FormControl mb={4}>
                    <FormLabel color={PRIMARY_COLOR}>Name</FormLabel>
                    <Input placeholder="Your Name"focusBorderColor={PRIMARY_COLOR} onChange={(e) => {setName(e?.target?.value)}} value={name}/>
                </FormControl>
                <FormControl mb={4}>
                    <FormLabel color={PRIMARY_COLOR}>Email</FormLabel>
                    <InputGroup>
                        <Input placeholder='Your Email*' type="email" focusBorderColor={PRIMARY_COLOR} onChange={(e) => {setEmail(e?.target?.value)}} value={email}/>
                        {isValidEmail(email)?<InputRightElement>
                        <CheckIcon color={PRIMARY_COLOR}/>
                        </InputRightElement>:null}
                </InputGroup>
                </FormControl>
                <FormControl mb={4}>
                    <FormLabel color={PRIMARY_COLOR}>Phone Number</FormLabel>
                    <HStack>
                        <Select  focusBorderColor={PRIMARY_COLOR} w="200px" mr="10px" placeholder='Select Country' onChange={(e) => {setCountryCode(e.target.value)}} value={countryCode}>
                            {
                                countryPhoneCodes.map((element) => {
                                return(<>
                                <option key={element.code} value={element.phoneCode}><FlagOption data={element}/></option>
                                </>)
                                })
                            }
                        </Select>
                        <Input type="tel" placeholder="Your Phone Number" ml={"10px"} focusBorderColor={PRIMARY_COLOR} onChange={(e) => {setPhoneNumber(e?.target?.value)}} value={phoneNumber}/>
                    </HStack>
                </FormControl>
                <FormControl mb={4}>
                    <FormLabel color={PRIMARY_COLOR}>How can we help you ?</FormLabel>
                    <Select placeholder="Select a Topic"  focusBorderColor={PRIMARY_COLOR} onChange={(e) => {setQueryType(e.target.value)}} value={queryType}>
                        <option value="General Inquiry">General Inquiry</option>
                        <option value="Support">Support</option>
                        <option value="Feedback">Feedback</option>
                    </Select>
                </FormControl>
            </Box>
            <Box flex="1">
                <FormControl mb={4}>
                    <FormLabel color={PRIMARY_COLOR}>Message</FormLabel>
                    <Textarea h="208px" placeholder="Your Message" focusBorderColor={PRIMARY_COLOR} onChange={(e) => {setDescription(e.target.value)}} value={description}/>
                </FormControl>
                <FormControl mb={4} alignItems={"left"}>
                    <Flex alignItems="flex-start">
                        <Checkbox ml={"2px"} isChecked={checkMailingList}
                        onChange={(e) => setCheckMailingList(e.target.checked)}
                        color={PRIMARY_COLOR}>Join our mailing list</Checkbox>
                    </Flex>
                </FormControl>

                <Button width="full" bg={PRIMARY_COLOR} 
                                color="white"
                                _hover={{color:"#ffffff", bg: PRIMARY_COLOR}}
                                isLoading={isLoading}
                                loadingText='Submitting'
                                onClick={() => {sendDescriptionMail()}}
                                isDisabled={(!isValidEmail(email) || (description.length === 0))}>
                    Send Message
                </Button>
            </Box>
        </Flex>
        {(!isCountryCodeValid)?<Text color="red">Please select valid country code</Text>:null}
        </Box>
    );
};

export default HomeContactUs;
