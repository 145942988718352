import React from 'react';
import { Box, Button, Flex, HStack, Heading, Image, Text, VStack } from "@chakra-ui/react";
import startBusinessIcon from "../assets/icons/startBusinessIcon.png";
import acraColorIcon from "../assets/icons/acraColorIcon.png";
import switchToSequenceIcon from "../assets/icons/switchToSequenceIcon.png";
import pricingIcon from "../assets/icons/pricingIcon.png";
import zeroPaperWorkIcon from "../assets/icons/zeroPaperWorkIcon.png";
import { OSOME_BEIGE_COLOR, PRIMARY_COLOR, PRIMARY_FONT } from '../constant';

const CardComponent = ({ icon, heading, content1, content2, buttonText }) => {
    return (
      <Box
        p={5}
        boxShadow="0 0 20px rgba(0, 0, 0, 0.12)"
        borderWidth="1px"
        borderRadius="md"
         w="800px"
         h="950px"
         bg={OSOME_BEIGE_COLOR}
         position="relative">
        <VStack spacing={4} align="start">
          <Box>
            <Flex h="180px" direction={"column"}>
                <Heading size="xl" color={PRIMARY_COLOR} fontFamily={PRIMARY_FONT} textAlign={"left"}>{heading}</Heading>
                <Text mt={2} color={PRIMARY_COLOR} fontFamily={PRIMARY_FONT} textAlign={"left"}>{content1}</Text>
                <Text mt={2} color={PRIMARY_COLOR} fontFamily={PRIMARY_FONT} textAlign={"left"}>{content2}</Text>    
            </Flex>
          </Box>
          <Button size="lg" bgColor={PRIMARY_COLOR} mb="20px" color="white" onClick={() => {}} alignSelf={"flex-start"}>
                {buttonText}
            </Button>
        </VStack>
        <Image
            src={icon} // Use the icon prop as the image source
            alt="Card Icon"
            position="absolute"
            bottom="0"
            left="0"
            right="0"
            width="100%"    // Full width of the card
            height="70%"    // 30% of the card's height
            objectFit="cover" // Ensures the image covers the entire space
            borderBottomRadius="md" // Optional: Rounds the bottom of the image if needed
        />
      </Box>
    );
  };

function StartJourney(props) {
    return (
        <HStack spacing={6} justifyContent="center">
            <CardComponent
                icon={startBusinessIcon}
                heading="Incorporate a company"
                content1=" Whether you are a local or a foreigner, starting a company in Singapore can be simple with a reliable compliance partner. Do you have a preferred company name for your startup?"
                content2="Let us know, and we will help you check its availability for free!"
                buttonText="Check Your Company Name Availability"
            />
            <CardComponent
                icon={switchToSequenceIcon}
                heading="Switch to Sequence"
                content1="We offer transparent pricing for every stage of your business. We will also take care of all the transitions from your old company secretary to us so that you’ll never have to worry about misplaced documents again. All your files will be securely stored in our database, making it easier to organize and access."
                content2="Let us know, and we will help you check its availability for free!"
                buttonText="Check Our Pricing List"
            />
        </HStack>
    )
}

export default StartJourney;
