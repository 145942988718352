import React, { useEffect, useState } from 'react';
import { RiShieldCheckLine } from "react-icons/ri";
import { PRIMARY_COLOR, PRIMARY_FONT } from '../constant.js';
import { EMPTY, SUBMIT } from '../LandingPage/Strings/en_strings.js';
import { isValidEmail } from '../LandingPage/Utilities/utilities.js';
import { countryPhoneCodes } from '../countryCodes.js';
import { sendEmail } from '../services/serviceManager.js';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Center,
  InputGroup,
  Flex,
  Box,
  Text,
  Icon,
  Button,
  Textarea,
  Input,
  Select,
  useToast,
  InputRightElement
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

export const FlagOption = ({ data }) => (
  <Box display="flex" alignItems="center" p="2px 8px">
    <Text ml="5px">{data.phoneCode + "  " + data.name}</Text>
  </Box>
);

function ContactUs(props) {
  const toast = useToast();
  const { 
    isOpen,
    setIsContactOpen,
    pricingDetails,
    isFromHome 
  } = props;

  const [ name, setName ] = useState(EMPTY);
  const [ email, setEmail ] = useState(EMPTY);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ countryCode, setCountryCode ] = useState("+65");
  const [ description, setDescription ] = useState(EMPTY);
  const [ phoneNumber, setPhoneNumber ] = useState(EMPTY);
  const [ isCountryCodeValid, setIsCountryCodeValid ] = useState(true);

  useEffect(() => {
    setIsCountryCodeValid(true);
  },[countryCode]);

  useEffect(() => {
    const lowerCaseEmail = email?.toLowerCase();
    if(email && email !== lowerCaseEmail){
      setEmail(lowerCaseEmail);
    }
  },[email]);

  const sendDescriptionMail = () => {
      const countryCodeItem = countryPhoneCodes.find(element => element.phoneCode === countryCode);
      const isCountryCodeCorrect = countryCodeItem?true:false;
      const isPhoneEmpty = phoneNumber === EMPTY;

      if(!isPhoneEmpty && !isCountryCodeCorrect){
        setIsCountryCodeValid(false);
      }else{
        const details = {
          query: isFromHome?pricingDetails:description,
          email:email??"",
          name:name??"",
          number: countryCodeItem?.phoneCode + " - " + phoneNumber
        }

        sendEmail(onSuccess, onFailure, details);
        setIsLoading(true);
      }
  } 

  const resetValues = () => {
    setIsLoading(false);
    setEmail(EMPTY);
    setName(EMPTY);
    setPhoneNumber(EMPTY);
    setCountryCode("+65");
    setDescription(EMPTY);
  }

  const onSuccess = (response) => {
    resetValues();
    toast({
      title: 'Success',
      description: "Query submitted!",
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
    setIsContactOpen(false);
  }

  const onFailure = (response) => {
    setIsLoading(false);
    toast({
      title: 'Failed',
      description: "Server error, please wait for sometime. and send your request again",
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  }
    

    return (
      <>
        <Modal isOpen={isOpen} onClose={() =>{setIsContactOpen(false)}} isCentered>
          <ModalOverlay />
          <ModalContent fontFamily={PRIMARY_FONT}>
            <ModalHeader>
              <Box display="flex" alignItems="center">
                <Text fontSize="lg" mr="5px" color={PRIMARY_COLOR}>Contact Sequence</Text>
                <Icon as={RiShieldCheckLine} color={PRIMARY_COLOR}/>
              </Box>
            </ModalHeader>
            <ModalCloseButton onClick={() => {setIsContactOpen(false)}}/>
            <ModalBody>
            <Text color={PRIMARY_COLOR} mb="10px">Don’t worry, we will get back to you within a day🙂</Text>
              <InputGroup mb="10px">
                <Input placeholder='Your name' focusBorderColor={PRIMARY_COLOR} onChange={(e) => {setName(e?.target?.value)}} value={name}/>
              </InputGroup>
              <InputGroup mb="10px">
                <Select w="200px" mr="10px" placeholder='Select Country' onChange={(e) => {setCountryCode(e.target.value)}} value={countryCode}>
                      {
                        countryPhoneCodes.map((element) => {
                          return(<>
                          <option key={element.code} value={element.phoneCode}><FlagOption data={element}/></option>
                          </>)
                        })
                      }
                </Select>
                <Input placeholder='Your number ' type="number" focusBorderColor={PRIMARY_COLOR} onChange={(e) => {setPhoneNumber(e?.target?.value)}} value={phoneNumber}/>
              </InputGroup>
              <InputGroup mb="10px">
                <Input placeholder='Your email*' focusBorderColor={PRIMARY_COLOR} onChange={(e) => {setEmail(e?.target?.value)}} value={email}/>
                {isValidEmail(email)?<InputRightElement>
                  <CheckIcon color={PRIMARY_COLOR}/>
                </InputRightElement>:null}
              </InputGroup>
              {!isFromHome?<InputGroup mb="10px">
                  <Textarea h="300px" isDisabled={isLoading} placeholder='Type your query*' focusBorderColor={PRIMARY_COLOR} onChange={(e) => {setDescription(e.target.value)}} value={description}/>
              </InputGroup>:
              <Text px="10px">{pricingDetails}</Text>}
              {(!isCountryCodeValid)?<Text color="red">Please select valid country code</Text>:null}
            </ModalBody>
            <Center>
              <ModalFooter  w="100%">
                <Flex w="100%" direction="column" >
                <Button mb="10px" w="100%" 
                        bg={PRIMARY_COLOR} 
                        color="white"
                        _hover={{color:"#ffffff", bg: PRIMARY_COLOR}}
                        isLoading={isLoading}
                        loadingText='Submitting'
                        onClick={() => {sendDescriptionMail()}}
                        isDisabled={(!isValidEmail(email) || (description.length === 0 && pricingDetails.length === 0))}>
                            {SUBMIT}
                </Button>
                </Flex>
              </ModalFooter>
            </Center>
          </ModalContent>
        </Modal>
      </>
    )
  }

export default ContactUs;