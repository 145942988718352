import { Box, Center, Text, useBoolean } from "@chakra-ui/react";
import { PRIMARY_COLOR, PRIMARY_FONT, SECONDARY_BG_SHADE_COLOR } from "../constant";

const ButtonSwitch = (props) => {
    const {
        isOn,
        setIsOn,
        onText,
        offText
    } = props;

    return (
        <Box
        w="485px"
        h="70px"
        bg={isOn ? PRIMARY_COLOR : SECONDARY_BG_SHADE_COLOR}
        borderRadius="full"
        p="2px"
        display="flex"
        alignItems="center"
        cursor="pointer"
        fontFamily={PRIMARY_FONT}
        onClick={setIsOn.toggle}
        justifyContent={isOn ? "flex-end" : "flex-start"}
        transition="background-color 0.6s ease"
        mb="30px">
            {isOn?<Text fontSize={"20px"} color="white" mr={"25px"}>
                {offText}
            </Text>:null}
            <Box w="250px"
                h="65px"
                bg="white"
                borderRadius="full"
                boxShadow="md"
                transition="all 0.6s ease">
                <Center>
                    <Text color="black" fontSize={"20px"} pt={"15px"}>
                        {isOn ? onText : offText}
                    </Text>
                </Center>
            </Box>
            {!isOn?<Text fontSize={"20px"} color="black" ml="10px">
                {onText}
            </Text>:null}
        </Box>
    );
};

export default ButtonSwitch;
