import React, { useEffect, useState } from 'react';
import { Box, Flex, Text} from '@chakra-ui/react';
import HeaderExp from '../experiment/HeaderExp';
import Footer from '../LandingPage/Footer';
import { useLocation } from 'react-router-dom';
import sequence_tos from '../assets/sequence_tos.pdf'; 
import sequence_dpp from '../assets/sequence_dpp.pdf';
import FAQ from '../LandingPage/FAQ';

function Guides(props) {
  const location = useLocation();
  const [file, setFile] = useState(sequence_tos);
  useEffect(() => {
    if(location.pathname === "/tos"){
      setFile(sequence_tos);
    }else if(location.pathname === "/dpp"){
      setFile(sequence_dpp);
    }
  },[location]);

  return (
    <Box>
      <HeaderExp showMenu={false}/>
      <Flex direction="column" mt="80px" bg="white" alignItems="stretch" pb="150px">
        <Flex justify="center">                            
            <Text fontSize={{ base: "2xl", sm:"3xl", md: "5xl", lg: "5xl", xl: "5xl" }} mt="10px" color="black" fontFamily="Moderustic" as='b'>Frequently Asked Questions</Text>
        </Flex>
        <Flex w={{ base: "100%", sm:"100%", md: "100%", lg: "100%", xl: "100%" }} p="25px">
            <FAQ/>
        </Flex>
      </Flex>
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Footer />
      </Box>
    </Box>
  )
}

export default Guides;
