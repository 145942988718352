import React from 'react';
import {
    Box,
    Flex,
    Text,
    Link,
    Stack,
    HStack,
    IconButton,
    useColorModeValue,
    Button,
    VStack,
    Icon,
    Center,
    Card,
} from '@chakra-ui/react';
import { 
    FaLinkedin,
    FaInstagram,
    FaTiktok,
    FaFacebook,
    FaYoutube
} from "react-icons/fa";
import { useLocation, useNavigate } from 'react-router-dom';
import { PRIMARY_COLOR, PRIMARY_FONT, SERVICE_INDEXES } from '../constant';

const Footer = (props) => {
    const {
        handleScrollToItem
    } = props;
    const location = useLocation();
    const isRoot = location.pathname === "/";
    const bg = useColorModeValue('#7201a9', 'white');
    const color = useColorModeValue('white', '#7201a9');
    const addressUrl = "https://www.google.com/maps/place/Sequence+SG+BizCo+Pte.+Ltd./@1.2784458,103.8460723,17z/data=!4m6!3m5!1s0x31da19c8abcde7db:0xc7f550de873dc214!8m2!3d1.2784458!4d103.848261!16s%2Fg%2F11y4zygnmg?entry=ttu";
    const address1 = "Sequence SG BizCo Pte. Ltd.";
    const address2 = "UEN 202346260R  |  FA 20230485";
    const address3 = "140 Robinson Road, #09-02,";
    const address4 = " Tahir Building, Singapore 068907";
    const pipeSymbol = " | ";
    const SocialsText = "SOCIALS:";
    const AddressText = "ADDRESS:";
    const openingHours = "OPENING HOURS:";
    const weekdaysTimings = "Monday - Friday (9am -8pm)";
    const saturdayTimings = "Saturday (9am - 3pm)";
    const sundayTimings = "Sunday (Closed)";
    const infoEmail = "info@sequence.sg";
    const contactNumber = "+65 8138 9137";

    const tosText = "terms of service";
    const dppText = "data protection policy";
    const faqText = "FAQs";
    const contactUsText = "Contact Us";
    const navigate = useNavigate();
    return (
        <Box
            as="footer"
            role="contentinfo"
            py={3}
            px={8}
            bg={bg}
            color={color}>
                <Center>
                    <Box p={8} 
                        w="1280px"
                        fontFamily={PRIMARY_FONT}
                        my="15px">
                            <Flex gap={6}>
                                <Box flex="1" mr="30px">
                                    <Flex>
                                        <Link href={addressUrl} isExternal>
                                            <Button onClick={()=>{}}  variant="link" color='#ffffff' textDecoration="underline">
                                                <VStack>
                                                    <Text fontSize={"md"} textAlign={"left"}>{address1}</Text>
                                                    <Text fontSize={"md"} textAlign={"left"}>{address2}</Text>
                                                </VStack>
                                            </Button>
                                        </Link>
                                    </Flex>
                                    <Card bg={"white"} p="20px" mt="15px">
                                        <Text fontSize={"xl"} color={PRIMARY_COLOR} textAlign={"left"} fontWeight={"bold"}>{AddressText}</Text>
                                        <Text fontSize={"sm"} color={PRIMARY_COLOR} textAlign={"left"}>{address3}</Text>
                                        <Text fontSize={"sm"} color={PRIMARY_COLOR} textAlign={"left"}>{address4}</Text>
                                        <Text fontSize={"sm"} color={PRIMARY_COLOR} textAlign={"left"}>{infoEmail}</Text>
                                        <Text fontSize={"sm"} color={PRIMARY_COLOR} textAlign={"left"}>{contactNumber}</Text>
                                        
                                        <Text fontSize={"xl"} color={PRIMARY_COLOR} textAlign={"left"} fontWeight={"bold"} mt="10px">{openingHours}</Text>
                                        <Text fontSize={"sm"} color={PRIMARY_COLOR} textAlign={"left"}>{weekdaysTimings}</Text>
                                        <Text fontSize={"sm"} color={PRIMARY_COLOR} textAlign={"left"}>{saturdayTimings}</Text>
                                        <Text fontSize={"sm"} color={PRIMARY_COLOR} textAlign={"left"}>{sundayTimings}</Text>
                                    </Card>
                                </Box>
                                <Box flex="1">
                                    <Card bg={"white"} p="20px">
                                        <Text fontSize={"xl"} color={PRIMARY_COLOR} textAlign={"left"} fontWeight={"bold"}>{SocialsText}</Text>
                                        <Stack direction="row" spacing={6}>
                                            <Link href="https://linkedin.com" isExternal>
                                                <IconButton
                                                    icon={<FaLinkedin />}
                                                    isRound
                                                    variant="ghost"
                                                    aria-label="LinkedIn"
                                                    color={PRIMARY_COLOR}/>
                                            </Link>
                                            <Link href="https://instagram.com" isExternal>
                                                <IconButton
                                                    icon={<FaInstagram/>}
                                                    isRound
                                                    variant="ghost"
                                                    aria-label="Instagram"
                                                    color={PRIMARY_COLOR}/>
                                            </Link>
                                            <Link href="https://tiktok.com" isExternal>
                                                <IconButton
                                                    icon={<FaTiktok />}
                                                    isRound
                                                    variant="ghost"
                                                    aria-label="TikTok"
                                                    color={PRIMARY_COLOR}/>
                                            </Link>
                                            <Link href="https://youtube.com" isExternal>
                                                <IconButton
                                                    icon={<FaYoutube/>}
                                                    isRound
                                                    variant="ghost"
                                                    aria-label="x"
                                                    color={PRIMARY_COLOR}/>
                                            </Link>
                                            <Link href="https://facebook.com" isExternal>
                                                <IconButton
                                                    icon={<FaFacebook/>}
                                                    isRound
                                                    variant="ghost"
                                                    aria-label="Facebook"
                                                    color={PRIMARY_COLOR}/>
                                            </Link>
                                        </Stack>
                                    </Card>
                                    <Flex>
                                        <Link>
                                            <Button onClick={()=>{navigate("/tos")}}  variant="link" color='#ffffff' textDecoration="underline">
                                                <Text fontSize={"md"} textAlign={"left"} mt="20px">{tosText}</Text>
                                            </Button>
                                        </Link>
                                    </Flex>
                                    <Flex mt="10px">
                                        <Link>
                                            <Button onClick={()=>{navigate("/dpp")}}  variant="link" color='#ffffff' textDecoration="underline">
                                                <Text fontSize={"md"} textAlign={"left"} >{dppText}</Text>
                                            </Button>
                                        </Link>
                                    </Flex>
                                    {(isRoot)?<><Flex mt="25px">
                                        <Link>
                                            <Button onClick={()=>{handleScrollToItem(SERVICE_INDEXES["Guides & FAQs"])}}  variant="link" color='#ffffff' textDecoration="underline">
                                                <Text fontSize={"md"} textAlign={"left"} >{faqText}</Text>
                                            </Button>
                                        </Link>
                                    </Flex>
                                    <Flex mt="10px">
                                        <Link>
                                            <Button onClick={()=>{handleScrollToItem(SERVICE_INDEXES["Contact Us"])}}  variant="link" color='#ffffff' textDecoration="underline">
                                                <Text fontSize={"md"} textAlign={"left"} >{contactUsText}</Text>
                                            </Button>
                                        </Link>
                                    </Flex></>:null}
                                </Box>
                            </Flex>
                    </Box>
                </Center>
        </Box>
    );
};

export default Footer;
