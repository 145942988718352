import React from 'react';
import { Box, HStack, Heading, Image, Text, VStack } from "@chakra-ui/react";
import acraIcon from "../assets/icons/acraIcon.png";
import acraColorIcon from "../assets/icons/acraColorIcon.png";
import customerSupportIcon from "../assets/icons/customerSupportIcon.png";
import pricingIcon from "../assets/icons/pricingIcon.png";
import zeroPaperWorkIcon from "../assets/icons/zeroPaperWorkIcon.png";
import { OSOME_BEIGE_COLOR, PRIMARY_COLOR, PRIMARY_FONT } from '../constant';

const CardComponent = ({ icon, heading, content }) => {
    return (
      <Box
        p={5}
        boxShadow="0 0 20px rgba(0, 0, 0, 0.12)"
        borderWidth="1px"
        borderRadius="md"
         w="400px"
         h="280px"
         bg={OSOME_BEIGE_COLOR}>
        <VStack spacing={4}>
          <Image src={icon} alt={heading} boxSize="75px"/>
          <Box>
            <Heading size="md" color={PRIMARY_COLOR} fontFamily={PRIMARY_FONT}>{heading}</Heading>
            <Text mt={2} color={PRIMARY_COLOR} fontFamily={PRIMARY_FONT}>{content}</Text>
          </Box>
        </VStack>
      </Box>
    );
  };

function WhySequence(props) {
    return (
        <HStack spacing={6} justifyContent="center">
            <CardComponent
                icon={zeroPaperWorkIcon}
                heading="Completely Paperless"
                content="Enjoy the convenience of a fully digital experience with no paperwork involved."
            />
            <CardComponent
                icon={customerSupportIcon}
                heading="Outstanding Customer Support"
                content="We are always help you in every step of your business."
            />
            <CardComponent
                icon={pricingIcon}
                heading="Transparent Pricing"
                content="We provide transparent pricing with no hidden costs."
            />
            <CardComponent
                icon={acraColorIcon}
                heading="Registered Filing Agent"
                content="No need to worry about deadlines or filing paperwork and you can start focusing on your core business. We are an authorized ACRA licensed filing agent (FA 20230485) and we will handle all the filings for you."
            />
        </HStack>
    )
}

export default WhySequence;
