import React, { useEffect, useRef, useState } from 'react';
import { 
    Box,
    Card,
    Center,
    Flex, 
    Grid, 
    GridItem, 
    HStack, 
    Icon, 
    Image, 
    ListItem, 
    Text,
    UnorderedList,
    useBoolean,
    VStack
} from '@chakra-ui/react';
import Footer from '../LandingPage/Footer.js';
import HeaderExp from '../experiment/HeaderExp';
import FAQ from '../LandingPage/FAQ.js';
import { MdStar } from "react-icons/md";

import { accountingPricingPlans, addOnList, COMPANY_LOGOS, corporatePricingPlans, ECO_SYSTEM_LOGOS, OSOME_BEIGE_COLOR, PRIMARY_COLOR, SERVICE_INDEXES, SERVICES_DETAILS, SOLAR_BANNER_BA, SOLAR_BANNER_L } from '../constant.js';
import { UpdatedSeqService } from '../experiment/UpdatedSeqService.js';
import { chunkArray } from '../LandingPage/Utilities/utilities.js';
import PricingSection from '../experiment/PricingCard.js';
import { EMPTY } from '../LandingPage/Strings/en_strings.js';
import { useNavigate } from 'react-router-dom';
import WhySequence from '../experiment/WhySequence.js';
import StartJourney from '../experiment/StartJourney.js';
import ButtonSwitch from '../experiment/ButtonSwitch.js';
import AddOnTable from '../experiment/AddOnTable.js';
import HomeContactUs from '../experiment/HomeContactUs.js';

function Home() {

    const [isPackageOn, setIsPackageOn] = useBoolean(true);
    const navigate = useNavigate();
    const itemRefs = useRef([]);
    const [isContactOpen, setIsContactOpen] = useState(false);
    const isFromHome = useRef(false);
    const pricingDetails = useRef(EMPTY);
    const ecoList = useRef(chunkArray(ECO_SYSTEM_LOGOS,3));
    const compList = useRef(chunkArray(COMPANY_LOGOS,3));

    const handleScrollToItem = (index) => {
        if(index === SERVICE_INDEXES['Guides & FAQs']){
            navigate("/guides");
        }
        else if (itemRefs.current[index]) {
            itemRefs.current[index].scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        if(!isContactOpen){
            isFromHome.current = false;
            pricingDetails.current = EMPTY;
        }
    },[isContactOpen]);

    return (
        <Box>
            <HeaderExp 
                handleScrollToItem={(index) => {handleScrollToItem(index)}} 
                isContactOpen={isContactOpen} 
                setIsContactOpen={setIsContactOpen}
                isFromHome={isFromHome.current}
                pricingDetails={pricingDetails.current}/>
            <Flex h="980px" mt="80px"  bgImage={{ base: SOLAR_BANNER_BA, sm: SOLAR_BANNER_BA, md: SOLAR_BANNER_L, lg: SOLAR_BANNER_L, xl: SOLAR_BANNER_L}} justify={"center"}  bgRepeat="no-repeat" bgPosition="center" bgSize="cover">
                <Flex mt="150px">
                    <VStack mt={{base:"40px"}}>
                        <Text fontSize={{ base: "30px", md: "50px", lg: "70px", xl: "90px" }} color="white" fontFamily="Moderustic" p="5px" borderRadius={"15px"}> modern day compliance </Text>
                    </VStack>
                </Flex>
            </Flex>
            <Flex mb="80px" justify={"center"}>
                <VStack mt="40px">
                    <Text fontSize={{ base: "30px", sm:"40px", md: "50px", lg: "60px", xl: "50px" }} color={PRIMARY_COLOR} fontFamily="Moderustic" mb="30px"> Why sequence ? </Text>
                    <WhySequence />
                    <Text fontSize={{ base: "30px", sm:"40px", md: "50px", lg: "60px", xl: "50px" }} color={PRIMARY_COLOR} fontFamily="Moderustic" mt="30px"> Start your journey with us today </Text>
                    <Text fontSize={{ base: "30px", sm:"40px", md: "50px", lg: "35px", xl: "25px" }} color={PRIMARY_COLOR} fontFamily="Moderustic" mb="30px"> We offer flexible services to support you at every stage of your business journey. </Text>
                    <StartJourney/>
                </VStack>
            </Flex>
            <Flex my="80px" justify={"center"}>
                <VStack>
                    <ButtonSwitch onText={"Corporate Secretarial"} offText={"Accounting"} isOn={isPackageOn} setIsOn={setIsPackageOn}/>
                    <PricingSection pricingPlans={isPackageOn? corporatePricingPlans: accountingPricingPlans} isFromHome={isFromHome} pricingDetails={pricingDetails} setIsContactOpen={setIsContactOpen}/>
                    <Flex>
                        <AddOnTable title={"Add-ons that you may need for your package"} items={addOnList}/>
                    </Flex>
                </VStack>
            </Flex>
            <Flex direction="column" bg={OSOME_BEIGE_COLOR}>
                <Text fontSize={{ base: "30px", sm:"40px", md: "50px", lg: "60px", xl: "30px" }} color={PRIMARY_COLOR} fontFamily="Moderustic" mt="30px">  We also provide Transparent Pricing for every stage of your business </Text>
                <Text fontSize={{ base: "30px", sm:"40px", md: "50px", lg: "60px", xl: "50px" }} color={PRIMARY_COLOR} fontFamily="Moderustic" mt="20px"> Our Pricing List </Text>
                <Box>{SERVICES_DETAILS.map((element, index) => (<Box ref={(el) => (itemRefs.current[index] = el)} scrollMarginTop={90}><UpdatedSeqService element={element} index={index} key={index}/></Box>))}</Box>
            </Flex>
            <Flex bg={OSOME_BEIGE_COLOR} h={{ base: "750px", sm:"950px", md: "1350px", lg: "450px", xl: "450px" }} direction="column">
                <Text fontSize={{ base: "30px", sm:"30px", md: "30px", lg: "40px", xl: "40px" }} color="black" fontFamily="Moderustic" mt="30px"> trusted by our clients </Text>
                <Center>
                    <Flex justify={"center"} w="1281px" css={{'&::-webkit-scrollbar': {
                        display: 'none'
                    },
                    '-ms-overflow-style': 'none',
                    'scrollbar-width': 'none',
                    }} mt="10px" overflowX={{ base: "auto"}} whiteSpace="nowrap" display={{ base: "none", sm:"none", md: "none", lg: "flex", xl: "flex" }}>
                        <HStack>
                            <>{
                                COMPANY_LOGOS.map((element,index) => {
                                    return(
                                        <Card boxShadow="0 0 0px rgba(0, 0, 0, 0.25)" mx="15px" h={{ base: "70px", md: "80px", lg: "90px", xl: "100px" }} w={{ base: "70px", md: "80px", lg: "90px", xl: "100px" }}>
                                            <Image src={element} alt='SP' boxSize='100%' objectFit='contain' p="10px"/>
                                        </Card>
                                    )
                                })
                            }</>
                        </HStack>
                    </Flex>
                </Center>
                <VStack w="100%" p="25px" display={{ base: "flex", sm:"flex", md: "flex", lg: "none", xl: "none" }}>
                    <>{
                        compList.current.map((element,index) => {
                            return(
                                
                                    <HStack w="100%">
                                        <Center>
                                        <>{
                                            element.map((subElement, subIndex) => {
                                                return(
                                                <Flex w="33%">
                                                    <Image src={subElement} alt='SP' boxSize='90%' objectFit='contain' p="10px"/>
                                                </Flex>
                                                )
                                            })
                                        }</>
                                      </Center>  
                                    </HStack>
                                
                            )
                        })
                    }</>
                </VStack>
                <Text fontSize={{ base: "30px", sm:"30px", md: "30px", lg: "40px", xl: "40px" }} color="black" fontFamily="Moderustic" mt="40px"> part of the wider ecosystem </Text>
                <Center>
                    <Flex justify={"center"} css={{'&::-webkit-scrollbar': {
                        display: 'none'
                    },
                    '-ms-overflow-style': 'none',
                    'scrollbar-width': 'none',
                    }} mt="10px" overflowX={{ base: "auto"}} display={{ base: "none", sm:"none", md: "none", lg: "flex", xl: "flex" }}>
                        <HStack>
                            <>{
                                ECO_SYSTEM_LOGOS.map((element,index) => {
                                    return(
                                        <Card boxShadow="0 0 0px rgba(0, 0, 0, 0.25)" mx="15px" h={{ base: "70px", md: "80px", lg: "90px", xl: "100px" }} w={{ base: "70px", md: "80px", lg: "90px", xl: "100px" }}>
                                            <Image src={element} alt='SP' boxSize='100%' objectFit='contain' p="10px"/>
                                        </Card>
                                    )
                                })
                            }</>
                        </HStack>
                    </Flex>
                </Center>
                <VStack w="100%" p="25px" display={{ base: "flex", sm:"flex", md: "flex", lg: "none", xl: "none" }}>
                    <>{
                        ecoList.current.map((element,index) => {
                            return(
                                    <HStack w="100%" key={index}>
                                        <Center>
                                        <>{
                                            element.map((subElement, subIndex) => {
                                                return(
                                                <Flex w="33%" key={index + subIndex}>
                                                    <Image src={subElement} alt='SP' boxSize='90%' objectFit='contain' p="10px" />
                                                </Flex>
                                                )
                                            })
                                        }</>
                                      </Center>  
                                    </HStack>
                                
                            )
                        })
                    }</>
                </VStack>
            </Flex>
            <Flex bg={OSOME_BEIGE_COLOR} py="50px" ref={(el) => (itemRefs.current[5] = el)} scrollMarginTop={90}>
                <HomeContactUs/>
            </Flex>
            <Box display="flex" flexDirection="column" justifyContent="space-between">
                <Footer handleScrollToItem={handleScrollToItem}/>
            </Box>
        </Box>
  );
}

export default Home;